import { CloudArrowUpIcon } from '@heroicons/react/20/solid'
import { DocumentIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import { FeaturedIcon } from 'components/icons/FeaturedIcon'
import { formatAsFileSize } from 'lib/helpers/formatters'
import { useUser } from 'lib/hooks/useCurrentUser'
import { useInvestorDetails } from 'lib/hooks/useInvestorDetails'
import { KYCFormSchema } from 'lib/validators/schemas/kyc'
import Link from 'next/link'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { PrimaryButton } from '../Button'
import { InputWithLabel } from '../InputField'
import { ModalContentWrapper, ModalFooterWrapper } from '../Modal'

type KYCFormValues = z.infer<typeof KYCFormSchema>

const KYC_REGEX = new RegExp('^[0-9]{3}-[0-9]{2}-[0-9]{4}$')

const KYCForm = ({ onSuccess, investorDetails }: any) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    control,
  } = useForm<KYCFormValues>({
    resolver: zodResolver(KYCFormSchema),
    defaultValues: investorDetails,
  })

  const { user, mutate } = useUser()
  const values = watch()

  const calculateFlow = ():
    | 'USA_VERIFICATION'
    | 'DOCUMENT_VERIFICATION'
    | 'PENDING'
    | 'REJECTED'
    | 'APPROVED' => {
    if (user.kyc_aml_status === 'NOT_STARTED') {
      if (investorDetails?.citizenship === 'USA') return 'USA_VERIFICATION'
      return 'DOCUMENT_VERIFICATION'
    }

    if (user.kyc_aml_status === 'INFO_REQUIRED') return 'DOCUMENT_VERIFICATION'

    if (user.kyc_aml_status === 'REJECTED') return 'REJECTED'
    if (user.kyc_aml_status === 'APPROVED') return 'APPROVED'

    return 'PENDING'
  }

  const flow = calculateFlow()
  const formatAsSSN = (value: string) => {
    const ssn = value.replace(/[^\d]/g, '')

    if (ssn.length < 4) return ssn
    if (ssn.length < 6) return `${ssn.slice(0, 3)}-${ssn.slice(3)}`
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`
  }

  const ssnHint = useMemo<string | undefined>(() => {
    const ssn = formatAsSSN(values.ssn || '')
    if (KYC_REGEX.test(ssn)) return 'Your SSN is in the right format'

    return 'SSN must be in the format 123-45-6789'
  }, [values.ssn])

  const handleSubmitForm = async (data: KYCFormValues) => {
    try {
      if (flow === 'DOCUMENT_VERIFICATION') {
        const formData = new FormData()
        formData.append('file', data.file[0])
        await axios.post('/api/v1/users/document-verification', formData)
      }

      if (flow === 'USA_VERIFICATION') {
        data.ssn = formatAsSSN(data.ssn || '')

        await axios.put('/api/v1/users/investor-details', data)
      }

      await mutate()

      onSuccess()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <ModalContentWrapper>
        <div className="flex flex-col gap-y-4 pb-4">
          {flow === 'USA_VERIFICATION' && (
            <div>
              <InputWithLabel
                label="SSN"
                placeholder="123-45-6789"
                mask="999-99-9999"
                // name="ssn"
                // control={control}
                hint={ssnHint}
                reactHookFormProps={register('ssn')}
              />
            </div>
          )}
          {flow === 'DOCUMENT_VERIFICATION' && (
            <div>
              <h2 className="text-freeport-sm font-medium text-freeport-gray-700 pb-1.5">
                Document Verification
              </h2>
              <p className="text-freeport-sm font-regular text-freeport-gray-600 pb-1.5">
                Please select and upload a picture of your passport or national
                ID.
              </p>

              <label
                htmlFor="document_verification_file"
                className="block cursor-pointer group w-full my-4 rounded-xl text-center bg-white border border-freeport-gray-200 py-4 px-6"
              >
                <FeaturedIcon color="gray">
                  <CloudArrowUpIcon className="h-5 w-5" />
                </FeaturedIcon>

                <p className="text-freeport-sm text-freeport-gray-600 mt-3">
                  <strong className="font-semibold text-freeport-primary-700 group-hover:underline">
                    Click to select
                  </strong>{' '}
                  a picture of your passport or national ID.
                </p>
                <p className="text-freeport-xs text-freeport-gray-600 mt-1">
                  PNG, JPG, or PDF (max. 100mb)
                </p>
                <input
                  {...register('file', {
                    required:
                      'A picture of your passport or national ID is required',
                  })}
                  type="file"
                  id="document_verification_file"
                  accept=".jpg,.pdf,.png"
                  className="hidden"
                />
              </label>

              {values.file && values.file[0] && (
                <div className="flex flex-row items-center gap-3 my-4 rounded-xl bg-white border border-freeport-gray-200 p-4">
                  <div className="shadow-focus-ring-primary rounded-full w-8 h-8 border-[6px] border-freeport-primary-200 bg-freeport-primary-200">
                    <DocumentIcon className="h-5 w-5 text-freeport-primary-600" />
                  </div>
                  <div className="">
                    <p className="text-freeport-sm font-medium text-freeport-gray-700">
                      {values?.file[0]?.name}
                    </p>
                    <p className="text-freeport-sm font-regular text-freeport-gray-600">
                      {formatAsFileSize(values?.file[0]?.size || 0)}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          {flow === 'APPROVED' && (
            <div>
              <h2 className="text-freeport-sm font-medium text-freeport-gray-700 pb-1.5">
                Account is approved
              </h2>
              <p className="text-freeport-sm font-regular text-freeport-gray-600 pb-1.5">
                Your account has been approved for trading.
              </p>
            </div>
          )}
          {flow === 'REJECTED' && (
            <div>
              <h2 className="text-freeport-sm font-medium text-freeport-gray-700 pb-1.5">
                Account is rejected
              </h2>
              <p className="text-freeport-sm font-regular text-freeport-gray-600 pb-1.5">
                Your account has been rejected for trading.
              </p>
            </div>
          )}
          {flow === 'PENDING' && (
            <div>
              <h2 className="text-freeport-sm font-medium text-freeport-gray-700 pb-1.5">
                Account is pending
              </h2>
              <p className="text-freeport-sm font-regular text-freeport-gray-600 pb-1.5">
                Your account is still pending review.
              </p>
            </div>
          )}
        </div>
      </ModalContentWrapper>
      {flow === 'DOCUMENT_VERIFICATION' && (
        <ModalFooterWrapper>
          <PrimaryButton
            type="submit"
            isLoading={isSubmitting}
            disabled={!(values.file && values.file[0])}
          >
            {values.file && values.file[0]
              ? 'Upload and Verify'
              : 'Select file to upload'}
          </PrimaryButton>
          <p className="text-center mt-1 text-freeport-xs text-freeport-gray-600">
            Learn how we protect your data in our{' '}
            <Link
              href="/faq#data-security"
              target="_blank"
              className="text-freeport-primary-600 font-semibold hover:underline"
              referrerPolicy="no-referrer"
            >
              FAQ
            </Link>
          </p>
        </ModalFooterWrapper>
      )}
      {flow === 'USA_VERIFICATION' && (
        <ModalFooterWrapper>
          <PrimaryButton
            type="submit"
            disabled={!values.ssn}
            isLoading={isSubmitting}
          >
            {values.ssn ? 'Verify Identity' : 'Add SSN to verify'}
          </PrimaryButton>
          <p className="text-center mt-1 text-freeport-xs text-freeport-gray-600">
            Learn how we protect your data in our{' '}
            <Link
              href="/faq#data-security"
              target="_blank"
              className="text-freeport-primary-600 font-semibold hover:underline"
              referrerPolicy="no-referrer"
            >
              FAQ
            </Link>
          </p>
        </ModalFooterWrapper>
      )}
      {flow !== 'USA_VERIFICATION' && flow !== 'DOCUMENT_VERIFICATION' && (
        <ModalFooterWrapper>
          <PrimaryButton type="submit" isLoading={isSubmitting}>
            Continue
          </PrimaryButton>
          <p className="text-center mt-1 text-freeport-xs text-freeport-gray-600">
            Learn how we protect your data in our{' '}
            <Link
              href="/faq#data-security"
              target="_blank"
              className="text-freeport-primary-600 font-semibold hover:underline"
              referrerPolicy="no-referrer"
            >
              FAQ
            </Link>
          </p>
        </ModalFooterWrapper>
      )}
    </form>
  )
}

const KYCWrapper = ({ onSuccess }: any) => {
  const { investorDetails } = useInvestorDetails()

  if (!investorDetails)
    return (
      <div>
        <h2 className="text-freeport-sm font-medium text-freeport-gray-700 text-center pt-4 pb-8">
          Loading…
        </h2>
      </div>
    )
  return <KYCForm onSuccess={onSuccess} investorDetails={investorDetails} />
}

export { KYCWrapper as KYCForm }
