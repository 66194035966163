import { ReactElement, createContext, useContext, useState } from 'react'

interface ThemeContextProps {
  children: ReactElement
}

interface InviteDrawerType {
  inviteDrawerOpen: boolean
  handleInviteDrawerOpen: () => void
}

const ThemeContext = createContext<InviteDrawerType>({
  inviteDrawerOpen: false,
  handleInviteDrawerOpen: () => null,
})

const ThemeContextProvider = ({ children }: ThemeContextProps) => {
  const [inviteDrawerOpen, setInviteDrawerOpen] = useState(false)

  const handleInviteDrawerOpen = () => setInviteDrawerOpen(!inviteDrawerOpen)

  return (
    <ThemeContext.Provider value={{ inviteDrawerOpen, handleInviteDrawerOpen }}>
      {children}
    </ThemeContext.Provider>
  )
}

const useThemeContext = () => useContext(ThemeContext)

export { ThemeContextProvider, useThemeContext }
