import { Control, Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'

type InputField = {
  reactHookFormProps?: any
  type?: string
  label?: string
  placeholder?: string
  error?: string
  hint?: any
  mask?: string
  className?: string
} & React.InputHTMLAttributes<HTMLInputElement>

// Medium Variant
const InputWithLabel = ({
  reactHookFormProps,
  hint,
  error,
  label,
  mask,
  className,
  ...rest
}: InputField) => {
  // If a mask is provided, use the InputMask component
  const InputComponent = mask ? InputMask : 'input'

  return (
    <div className={className}>
      {label && (
        <label className="text-freeport-sm font-medium text-freeport-gray-700 pb-1.5">
          {label}
        </label>
      )}
      <InputComponent
        mask={mask}
        maskchar={null}
        className="px-[13px] py-[9px] bg-white text-freeport-md text-freeport-gray-900 placeholder-freeport-gray-500 border border-1 border-freeport-gray-300 rounded w-full shadow-sm focus:shadow-md focus:border-freeport-primary-300 disabled:text-freeport-gray-500 disabled:bg-freeport-gray-50"
        {...reactHookFormProps}
        {...rest}
      />
      {!error && hint && (
        <p className="pt-1.5 text-freeport-sm text-freeport-gray-600">{hint}</p>
      )}
      {error && (
        <p className="pt-1.5 text-freeport-sm text-freeport-error-500">
          {error}
        </p>
      )}
    </div>
  )
}

type ControlledInputWithLabelProps = {
  control: Control
  transform?: {
    input: (value: string) => string
    output: (event: React.ChangeEvent<HTMLInputElement>) => string
  }
  name: string
  defaultValue?: string
  className?: string
  label?: string
  error?: string
  hint?: string
  placeholder?: string
}

const DEFAULT_TRANSFORM = {
  input: (value: string) => value,
  output: (event: React.ChangeEvent<HTMLInputElement>) => event.target.value,
}

const ControlledInputWithLabel = ({
  control,
  transform = DEFAULT_TRANSFORM,
  name,
  label,
  error,
  hint,
  defaultValue,
  placeholder,
  className,
}: ControlledInputWithLabelProps) => (
  <Controller
    defaultValue={defaultValue}
    control={control}
    name={name}
    render={({ field }) => (
      <div className={className}>
        {label && (
          <label className="text-freeport-sm font-medium text-freeport-gray-700 pb-1.5">
            {label}
          </label>
        )}
        <input
          className="px-[13px] py-[9px] bg-white text-freeport-md text-freeport-gray-900 placeholder-freeport-gray-500 border border-1 border-freeport-gray-300 rounded w-full shadow-sm focus:shadow-md focus:border-freeport-primary-300 disabled:text-freeport-gray-500 disabled:bg-freeport-gray-50"
          onChange={(e) => field.onChange(transform.output(e))}
          value={transform.input(field.value)}
          placeholder={placeholder}
        />
        {!error && hint && (
          <p className="pt-1.5 text-freeport-sm text-freeport-gray-600">
            {hint}
          </p>
        )}
        {error && (
          <p className="pt-1.5 text-freeport-sm text-freeport-error-500">
            {error}
          </p>
        )}
      </div>
    )}
  />
)

export { ControlledInputWithLabel, InputWithLabel }
