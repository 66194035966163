import useSWR from 'swr'

import axios from 'axios'

const fetcher = () =>
  axios.get('/api/v1/users/investor-details').then((res) => res.data.data)

// NOTE: Important to not disclose any ultra sensitive (i.e. SSN) data here
const useInvestorDetails = () => {
  const { data, mutate, error, isLoading } = useSWR(
    '/api/v1/users/investor-details',
    fetcher
  )

  return {
    investorDetails: data,
    mutate,
    isLoading,
    isError: error,
  }
}

export { useInvestorDetails }
