import { Modal, ModalWrapper } from 'components/design-system/Modal'
import { Step, StepBridge } from 'components/design-system/ProgressSteps'
import { AccreditedInvestorForm } from 'components/design-system/forms/AccreditedInvestorForm'
import { KYCForm } from 'components/design-system/forms/KYCForm'
import { useKYC } from 'lib/hooks/useKYC'
import Link from 'next/link'
import { useState } from 'react'

const KYCSection = ({ handleSuccess }: any) => {
  return (
    <>
      <header className="relative pb-6">
        <div className="px-4">
          <h3 className="text-freeport-display-xs text-freeport-gray-900 font-semibold">
            Confirm your identity
          </h3>
          <p className="text-freeport-md text-freeport-gray-600 pt-3">
            Before we can issue you shares we&apos;re required by the SEC to
            confirm your identity. Verifying you are who you say you are helps
            us keep your investment safe. We take the protection of your data
            seriously, learn more in our{' '}
            <Link
              href="/faq#why-do-i-need-to-fill-in-this-personal-information-and-is-it-secure"
              className="font-bold hover:underline"
              target="blank"
              referrerPolicy="no-referrer"
            >
              FAQ
            </Link>
            .
          </p>
        </div>
      </header>
      <KYCForm onSuccess={() => handleSuccess()} />
    </>
  )
}

const AccreditedInvestorSection = ({ handleSuccess }: any) => {
  return (
    <>
      <header className="relative pb-6">
        <div className="px-4">
          <h3 className="text-freeport-display-xs text-freeport-gray-900 font-semibold">
            Investing experience
          </h3>
          <p className="text-freeport-md text-freeport-gray-600 pt-3">
            We&apos;d also like to know a little bit more about your investing
            experience.
          </p>
        </div>
      </header>
      <AccreditedInvestorForm onSuccess={() => handleSuccess()} />
    </>
  )
}

const KYCModal = ({ open = false, onClose }: any) => {
  const [step, setStep] = useState(1)
  const { requiresKYC, requiresAccreditedCheck } = useKYC()

  return (
    <Modal show={open} onClose={onClose}>
      <ModalWrapper>
        <div className="w-full flex flex-row justify-center items-center pt-8 pb-4">
          <Step
            active={step === 1}
            complete={!requiresKYC}
            handleClick={() => setStep(1)}
          >
            <p className="font-normal">Verify your information</p>
          </Step>
          <StepBridge complete={!requiresKYC} />
          <Step
            active={step === 2}
            complete={!requiresAccreditedCheck}
            handleClick={() => setStep(2)}
          >
            <p className="font-normal">Confirm your investor experience</p>
          </Step>
        </div>
        {step === 1 && (
          <KYCSection
            handleSuccess={() => {
              requiresAccreditedCheck ? setStep(2) : onClose()
            }}
          />
        )}
        {step === 2 && (
          <AccreditedInvestorSection
            handleSuccess={() => {
              requiresKYC ? setStep(1) : onClose()
            }}
          />
        )}
      </ModalWrapper>
    </Modal>
  )
}

export { KYCModal }
