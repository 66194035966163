import { Badge } from 'components/design-system/Badge'
import { PrimaryButton } from 'components/design-system/Button'
import { useKYC } from 'lib/hooks/useKYC'

const InfoRequired = ({ handleStartKYC }: any) => (
  <div className="flex flex-row justify-between items-center whitespace-nowrap">
    <Badge label="Account Requires More Info" color="yellow" />
    <PrimaryButton
      className="inline-block ml-4"
      onClick={() => handleStartKYC()}
    >
      Update
    </PrimaryButton>
  </div>
)

const KYCAMLStatus = ({ handleStartKYC }: any) => {
  const { status, requiresKYC, requiresAccreditedCheck, isLoading } = useKYC()

  if (isLoading) return null
  if (requiresKYC || requiresAccreditedCheck)
    return <InfoRequired handleStartKYC={handleStartKYC} />

  return (
    <>
      {status === 'APPROVED' && (
        <Badge label="Account Approved" color="green" />
      )}
      {status === 'REJECTED' && (
        <Badge label="Account Suspended" color="error" />
      )}
      {status === 'PENDING' && (
        <Badge label="Account Review In Progress" color="gray" />
      )}
    </>
  )
}

export { KYCAMLStatus }
