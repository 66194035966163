import useOutsideClick from 'lib/hooks/useOutsideClick'
import Link from 'next/link'
import { useRef, useState } from 'react'
import { UserResource } from 'src/resources/UserResource'

const getInitials = (user: any) => {
  if (user?.first_name && user?.last_name) {
    return (user.first_name[0] + user.last_name[0]).toUpperCase()
  }
  if (user?.email) {
    return user.email[0].toUpperCase()
  }
  return ''
}

const ProfileIcon = ({
  user,
  logOut,
}: {
  user: UserResource
  logOut: () => void
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const initials = getInitials(user)

  useOutsideClick(wrapperRef, () => {
    if (dropdownOpen) {
      setDropdownOpen(false)
    }
  })

  const LINKS = [
    {
      href: '/profile',
      text: 'Profile',
    },
    {
      href: '/profile/collection',
      text: 'Your Collection',
    },
    {
      href: '/profile/collection#transactions',
      text: 'Transactions',
    },
    {
      href: '/profile/payment-methods',
      text: 'Payment Settings',
    },
  ]

  return (
    <div className="relative" ref={wrapperRef}>
      <div
        className="flex items-center justify-center w-11 h-11 bg-freeport-primary-900 text-white font-bold rounded-full cursor-pointer select-none"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        {initials}
      </div>
      {dropdownOpen && (
        <ul className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg">
          {LINKS.map(({ href, text }) => (
            <li key={text}>
              <Link
                className="block w-full text-left hover:bg-gray-100 focus:bg-gray-100 focus:outline-none px-4 py-3 text-gray-900 text-sm font-medium"
                href={href}
                onClick={() => setDropdownOpen(false)}
              >
                {text}
              </Link>
            </li>
          ))}
          <li>
            <button
              className="w-full text-left hover:bg-gray-100 focus:bg-gray-100 focus:outline-none px-4 py-3 text-red-500 text-sm font-medium"
              onClick={logOut}
            >
              Log out
            </button>
          </li>
        </ul>
      )}
    </div>
  )
}

export { ProfileIcon }
