import { PrimaryButton, SecondaryButton } from 'components/design-system/Button'
import { Variants, motion } from 'framer-motion'
import Link from 'next/link'

interface NavProps {
  wrapperVariant: Variants
  itemVariant: Variants
  closeOverlay: () => void
}

const UnAuthenticatedNav = ({
  wrapperVariant,
  itemVariant,
  closeOverlay,
}: NavProps) => {
  const PRIMARY_LINKS = [
    {
      href: '/discover',
      text: 'Invest',
    },
    {
      href: '/gallery',
      text: 'Gallery',
    },
    {
      href: '/about',
      text: 'How It Works',
    },
    {
      href: '/blog',
      text: 'Blog',
    },
  ]

  return (
    <motion.ul variants={wrapperVariant}>
      {PRIMARY_LINKS.map(({ href, text }) => (
        <motion.li
          variants={itemVariant}
          key={text}
          className="mt-2 font-bold text-freeport-display-md md:text-freeport-display-lg font-['Poppins']"
        >
          <Link
            className="hover:text-freeport-blue-base"
            href={href}
            onClick={closeOverlay}
          >
            {text}
          </Link>
        </motion.li>
      ))}
      <motion.li variants={itemVariant} className="mt-4">
        <Link href="/auth/sign-up">
          <PrimaryButton className="w-auto">Sign Up</PrimaryButton>
        </Link>
      </motion.li>
      <motion.li variants={itemVariant} className="mt-2">
        <Link href="/auth/log-in">
          <SecondaryButton className="w-auto">Log In</SecondaryButton>
        </Link>
      </motion.li>
    </motion.ul>
  )
}

export { UnAuthenticatedNav }
