import axios, { AxiosResponse } from 'axios'

import { JSONAPIResponse } from 'lib/types/api'

export const axiosClient = axios.create({
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
})

axiosClient.interceptors.response.use(
  (res: AxiosResponse<JSONAPIResponse<any, string>>) => {
    if (res.data.status !== 200) {
      throw new Error(res.data.error)
    }

    return res
  }
)
