import { FreeportLogo } from 'components/icons/freeport/logo'
import Link from 'next/link'

const HeaderLogo = () => {
  return (
    <div className="py-4 flex items-center justify-between text-freeport-blue-dark">
      <Link href="/" title="freeport" rel="home">
        <FreeportLogo className="h-8 w-8" variant="logo-type" />
      </Link>
    </div>
  )
}

export { HeaderLogo }
