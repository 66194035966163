import { create } from 'zustand'

type KYCModalState = {
  showKYCModal: boolean
  setShowKYCModal: (val: boolean) => void
}

const useShowKYCModal = create<KYCModalState>((set) => ({
  showKYCModal: false,
  setShowKYCModal: (val) => set({ showKYCModal: val }),
}))

export { useShowKYCModal }
