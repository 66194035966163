import { PrimaryButton } from 'components/design-system/Button'
import { InputWithLabel } from 'components/design-system/InputField'
import { Modal } from 'components/modals/Modal'
import { axiosClient } from 'lib/clients/axios'
import { getUTMContext } from 'lib/helpers/getUTMContext'
import { stringToArray } from 'lib/helpers/stringToArray'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ConfirmationModal } from './ConfirmationModal'

type FormValues = {
  email: string
  early_access_code?: string
  referral_code?: string
  context?: any
}

const RequestForm = ({ section }: { section: string }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const handleCloseModal = () => setShowSuccessModal(false)

  const router = useRouter()
  const context = useMemo(
    () => (router?.query ? getUTMContext(router.query) : {}),
    [router]
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  // NOTE: setting defaultValues does not work as expected - router.query is blank
  // when pre-rendering.
  const { register, handleSubmit, setError } = useForm<FormValues>()

  const onSubmitForm = async (data: FormValues) => {
    // Set context from UTM params
    data.context = context
    setIsSubmitting(true)
    axiosClient
      .post('/api/v1/newsletter', data)
      .then((_res) => {
        setIsSubmitting(false)
        setShowSuccessModal(true)
      })
      .catch((res) => {
        // Wasn't successful, show errors
        const errors = res.response?.data?.errors || null
        setIsSubmitting(false)
        if (errors) {
          Object.keys(errors).forEach((field) => {
            const messages = stringToArray(errors[field] || [])
            setError(field as any, {
              type: 'server',
              message: messages.join(', '),
            })
          })
        } else {
          setError('email', {
            type: 'server',
            message: 'Something went wrong, please try again.',
          })
        }
      })
  }

  return (
    <>
      <div className="w-full">
        <form
          className="flex flex-col md:flex-row gap-4 items-start"
          onSubmit={handleSubmit(onSubmitForm)}
        >
          <InputWithLabel
            reactHookFormProps={register('email')}
            type="email"
            placeholder="Enter your email"
            autoComplete="true"
            hint={
              <>
                See how we use your data in our{' '}
                <Link
                  href="/privacy"
                  title="Privacy Policy"
                  className="font-semibold underline hover:no-underline"
                  target="_blank"
                >
                  privacy policy
                </Link>
              </>
            }
            className="w-full md:w-auto flex-grow"
            id={`${section}-email`}
            required
          />
          <PrimaryButton
            className="md:max-w-[10rem] flex-shrink-0"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Stay up to date
          </PrimaryButton>
        </form>
      </div>

      {showSuccessModal && (
        <Modal show={true} onClose={handleCloseModal}>
          <ConfirmationModal closeModal={handleCloseModal} />
        </Modal>
      )}
    </>
  )
}

export { RequestForm }
