// Legacy Footer

import { FreeportLogo } from 'components/icons/freeport/logo'
import { InstagramIcon } from 'components/icons/social/Instagram'
import { MediumIcon } from 'components/icons/social/Medium'
import { TwitterIcon } from 'components/icons/social/Twitter'
import { PublicDisclaimerWithLayout } from 'components/legal/PublicDisclaimerWithLayout'
import { useCurrentUser } from 'lib/hooks/useCurrentUser'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { RequestForm } from 'views/home/RequestForm'
import { LAYOUT_CONTAINER_CLASS } from './Layout'

const FooterLinkList = ({ title, links }: { title: string; links: any }) => {
  return (
    <div className="">
      <h3 className="text-freeport-sm font-semibold text-freeport-gray-500">
        {title}
      </h3>
      <ul className="pt-4 flex flex-col gap-y-3">
        {links.map(({ href, text }: any) => (
          <li key={href}>
            <Link
              href={href}
              className="text-freeport-md font-semibold text-freeport-gray-600"
            >
              {text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const PRODUCT_LINKS = [
  {
    text: 'Gallery',
    href: '/gallery',
  },
  {
    text: 'How It Works',
    href: '/about',
  },
  {
    text: 'FAQ',
    href: '/faq',
  },
]

const RESOURCES_LINKS = [
  {
    text: 'Blog',
    href: '/blog',
  },
  {
    text: 'Press',
    href: '/press',
  },
  {
    text: 'Privacy',
    href: '/privacy',
  },
  {
    text: 'Terms',
    href: '/terms',
  },
  {
    text: 'Disclaimer',
    href: '/legal/disclaimer',
  },
]

const COMPANY_LINKS = [
  {
    text: 'Team',
    href: '/team',
  },
  {
    text: 'Contact',
    href: '/contact',
  },
  {
    text: 'Jobs',
    href: '/jobs',
  },
]

const FooterLinks = () => {
  return (
    <div
      className={`${LAYOUT_CONTAINER_CLASS} py-12 md:py-16 grid md:grid-cols-2 gap-x-16`}
    >
      <div className="">
        <FreeportLogo className="h-8 w-8" variant="logo-type" />
        <p className="mt-6 md:mt-8 text-freeport-md text-freeport-gray-600">
          Ownership for all™
        </p>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-8 pt-12 md:pt-0">
        <FooterLinkList title="Product" links={PRODUCT_LINKS} />
        <FooterLinkList title="Resources" links={RESOURCES_LINKS} />
        <FooterLinkList title="Company" links={COMPANY_LINKS} />
      </div>
    </div>
  )
}

const SOCIAL_LINKS = [
  {
    text: 'Follow us on Medium',
    href: 'https://medium.com/@Freeport.app',
    glyph: <MediumIcon />,
  },
  {
    text: 'Follow us on Twitter',
    href: 'https://twitter.com/freeport_app/',
    glyph: <TwitterIcon />,
  },
  {
    text: 'Follow us on Instagram',
    href: 'https://www.instagram.com/freeport.app/',
    glyph: <InstagramIcon />,
  },
]

const SocialLinks = () => {
  return (
    <div className={`${LAYOUT_CONTAINER_CLASS}`}>
      <div className="pt-8 flex flex-col-reverse md:flex-row md:justify-between gap-y-6 md:items-center border-t border-t-gray-100">
        <p className="text-freeport-md text-freeport-gray-500">
          © 2023 Freeport Technologies, Inc. All rights reserved.
        </p>
        <ul className="flex flex-row items-center gap-6 justify-start md:justify-end">
          {SOCIAL_LINKS.map(({ href, text, glyph }: any) => (
            <li key={href}>
              <Link href={href} className="text-freeport-gray-400" title={text}>
                {glyph}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const RequestAnInvite = () => {
  const { user } = useCurrentUser()
  const { email } = useRouter().query
  if (user || email) return null
  return (
    <section className="bg-freeport-gray-50" id="cta-form">
      <div
        className={`${LAYOUT_CONTAINER_CLASS} py-10 md:py-12 grid lg:grid-cols-2 md:justify-items-center md:items-center gap-8`}
      >
        <div className="">
          <h2 className="text-freeport-display-xs md:text-freeport-display-sm font-semibold text-freeport-gray-900">
            Stay up to date
          </h2>
          <p className="text-freeport-md md:text-freeport-xl text-freeport-gray-600 pt-4">
            Enter your email and we&apos;ll keep you in the loop with updates to
            our product, artwork availability, and more.
          </p>
        </div>
        <div className="">
          <RequestForm section="cta" />
        </div>
      </div>
    </section>
  )
}

const AboutPageFootnotes = () => {
  const isAboutPage = useRouter().pathname.slice(0, 6) === '/about'
  return !isAboutPage ? null : (
    <div className={`${LAYOUT_CONTAINER_CLASS} mt-8`}>
      <p className="text-freeport-xs text-freeport-gray-500">
        * There is no guarantee a secondary market will develop or even if it
        does, will have ample liquidity.
      </p>
      <p className="text-freeport-xs text-freeport-gray-500">
        ** A return of original capital contributions and profits are not
        guaranteed and are dependent on the artwork appreciating and Freeport
        being able to find a buyer.
      </p>
    </div>
  )
}

const Footer = () => {
  return (
    <>
      <footer className="w-full">
        <RequestAnInvite />
        <FooterLinks />
        <SocialLinks />
        <AboutPageFootnotes />
        <PublicDisclaimerWithLayout />
      </footer>
    </>
  )
}

export { Footer }
