import classnames from 'classnames'
import { Spinner } from 'components/icons/app/Spinner'

const PrimaryButton = ({ children, isLoading = false, ...props }: any) => {
  const disabled = props.disabled || isLoading
  const className = classnames(
    'transition-all bg-freeport-primary-600 border border-freeport-primary-600 disabled:bg-freeport-primary-200 disabled:border-freeport-primary-200 text-freeport-md text-white font-semibold rounded-lg px-[18px] py-[10px] w-full',
    { 'scale-100 hover:scale-[101%] shadow-none hover:shadow-md': !disabled },
    props.className
  )
  return (
    <button {...props} disabled={disabled} className={className}>
      {isLoading ? <Spinner /> : children}
    </button>
  )
}

const SecondaryButton = ({ children, isLoading = false, ...props }: any) => {
  const className = classnames(
    'bg-white border border-freeport-gray-300 text-freeport-md text-freeport-gray-700 font-semibold rounded-lg px-[18px] py-[10px] w-full',
    props.className
  )

  return (
    <button {...props} className={className}>
      {isLoading ? <Spinner /> : children}
    </button>
  )
}

export { PrimaryButton, SecondaryButton }
