import { LAYOUT_CONTAINER_CLASS } from 'components/layout/App/Layout'
import { OFFERING_CIRCULAR } from 'lib/constants'
import { useRouter } from 'next/router'

const PublicDisclaimerWithLayout = () => {
  const router = useRouter()

  const showTTW =
    router.pathname === `/` ||
    router.pathname === `/discover` ||
    router.pathname.startsWith(`/art`)

  return (
    <div
      className={`${LAYOUT_CONTAINER_CLASS} text-freeport-xs text-freeport-gray-500 py-4 `}
    >
      {!showTTW ? null : (
        <>
          <p className="my-4">
            An affiliate of Freeport is anticipating making an offering of
            securities under Tier 2 of Regulation A. No money or other
            consideration is being solicited and, if sent in response, will not
            be accepted. No offer to buy securities can be accepted, and no part
            of the purchase price can be received, until an offering statement
            filed with the SEC has been qualified by the SEC. Any such offer may
            be withdrawn or revoked, without obligation or commitment of any
            kind, at any time before notice of acceptance given after the date
            of qualification by the SEC or as stated in the offering materials
            relating to an investment opportunity, as applicable. An indication
            of interest involves no obligation or commitment of any kind.{' '}
            <a
              href={OFFERING_CIRCULAR}
              className="underline hover:no-underline"
              target="_blank"
              rel="noreferrer"
            >
              View offering circular.
            </a>
          </p>
          <p className="my-4">
            By using this website, you accept our{' '}
            <a
              href="/terms"
              className="underline hover:no-underline"
              target="_blank"
              rel="noreferrer"
            >
              Terms
            </a>{' '}
            and{' '}
            <a
              href="/privacy"
              className="underline hover:no-underline"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
          <p className="my-4">
            This website contains certain forward-looking statements that are
            subject to various risks and uncertainties. Such statements use
            forward-looking terminology such as “may,” “will,” “should,”
            “potential,” “intend,” “expect,”, “outlook,” “seek,” “anticipate,”
            “estimate,” “approximately,” “believe,”, “could,” “project,”
            “predict,” or other similar words or expressions. Forward-looking
            statements are based on certain assumptions, discuss future
            expectations, describe future plans and strategies, or state other
            forward-looking information. Our ability to predict future events,
            actions, plans or strategies is inherently uncertain and actual
            outcomes may differ materially from those set forth or anticipated
            in such forward-looking statements. You are cautioned not to place
            undue reliance on any forward-looking statements. There is no
            guarantee of profits and investing includes risk of loss.
          </p>
          <p className="my-4">
            Past price trends are not indicative of future price trends and are
            not intended to be a proxy for historical or projected future
            performance of any specific artwork or offering. Our materials may
            present comparisons between the historical price performance of a
            segment of the art market and other investment asset classes, such
            as stocks, bonds, real estate, and others. There are important
            differences between art and other asset classes. Our materials may
            also include historical appreciation information based on auction
            sales and price trends. Such information is not intended to be
            indicative of returns that would have been achieved during such
            periods. Historical artwork appreciation rates and investment
            performance may differ significantly due to fees, expenses and other
            factors.
          </p>
          <p className="my-4">
            The operator of this website and its affiliates are not registered,
            licensed, or supervised as broker dealers or investment advisers by
            the SEC, the Financial Industry Regulatory Authority (FINRA), or any
            other financial regulatory authority or licensed to provide any
            financial advice or services. The information contained herein
            neither constitutes an offer for nor a solicitation of interest in
            any specific securities offering. For any proposed offering pursuant
            to an offering statement that has not yet been qualified by the SEC,
            no money or other consideration is being solicited, and if sent in
            response, will not be accepted. No offer to buy the securities can
            be accepted and no part of the purchase price can be received until
            the offering statement for such offering has been qualified by the
            SEC. Any such offer may be withdrawn or revoked, without obligation
            or commitment of any kind, at any time before notice of acceptance
            given after the date of qualification.
          </p>
          <p className="my-4">
            An indication of interest involves no obligation or commitment of
            any kind.
          </p>
        </>
      )}
    </div>
  )
}

export { PublicDisclaimerWithLayout }
