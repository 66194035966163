import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import { FauxCheckbox } from 'components/design-system/CheckboxGroup'
import { useInvestorDetails } from 'lib/hooks/useInvestorDetails'
import { AccreditedInvestorSchema } from 'lib/validators/schemas/kyc'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { PrimaryButton } from '../Button'
import { ModalContentWrapper, ModalFooterWrapper } from '../Modal'

type AccreditedInvestorFormValues = z.infer<typeof AccreditedInvestorSchema>

const AccreditedInvestorForm = ({ onSuccess, investorDetails }: any) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AccreditedInvestorFormValues>({
    resolver: zodResolver(AccreditedInvestorSchema),
    defaultValues: investorDetails,
  })
  const values = watch()

  useEffect(() => {
    if (!investorDetails?.first_name) return
    register('accredited_investor')
  }, [])

  const handleSubmitForm = async (data: AccreditedInvestorFormValues) => {
    try {
      await axios.put('/api/v1/users/investor-details', data)
      onSuccess()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <ModalContentWrapper>
          <div className="flex flex-col gap-y-4">
            <div>
              <h2 className="text-freeport-sm font-medium text-freeport-gray-700 pb-1.5">
                Are you an accredited investor?
              </h2>
              <fieldset className="grid grid-cols-2 gap-2">
                <FauxCheckbox
                  onClick={() => {
                    setValue('accredited_investor', false)
                  }}
                  label="No, I'm not"
                  active={values.accredited_investor === false}
                />
                <FauxCheckbox
                  onClick={() => setValue('accredited_investor', true)}
                  label="Yes, I am"
                  active={values.accredited_investor === true}
                />
              </fieldset>
              <div className="text-freeport-xs font-normal text-freeport-gray-600">
                <p className="pt-2">
                  <strong className="font-bold">Not sure</strong> if you&apos;re
                  an accredited investor?
                </p>
                <p className="pt-2">
                  Accredited Investors are those who meet certain income or net
                  worth thresholds defined by the SEC:{' '}
                </p>
                <ul className="pt-2">
                  <li className="flex flex-row items-center gap-1">
                    <InformationCircleIcon className="w-3 h-3 text-freeport-gray-300" />{' '}
                    $1,000,000+ net worth
                  </li>
                  <li className="flex flex-row items-center gap-1">
                    <InformationCircleIcon className="w-3 h-3 text-freeport-gray-300" />{' '}
                    $200,000+ income
                  </li>
                  <li className="flex flex-row items-center gap-1">
                    <InformationCircleIcon className="w-3 h-3 text-freeport-gray-300" />{' '}
                    Specific licenses
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ModalContentWrapper>
        <ModalFooterWrapper>
          <PrimaryButton isLoading={isSubmitting} type="submit">
            Submit
          </PrimaryButton>
        </ModalFooterWrapper>
      </form>
    </>
  )
}

const AccreditedInvestorWrapper = ({ onSuccess }: any) => {
  const { investorDetails } = useInvestorDetails()

  if (!investorDetails) return <div>Loading…</div>
  return (
    <AccreditedInvestorForm
      onSuccess={onSuccess}
      investorDetails={investorDetails}
    />
  )
}

export { AccreditedInvestorWrapper as AccreditedInvestorForm }
