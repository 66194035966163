import Router from 'next/router'
import { useEffect } from 'react'
import useSWR from 'swr'

import axios from 'axios'
import SuspenseScreen from 'src/components/layout/App/Suspense'
import { UserResource } from 'src/resources/UserResource'

const fetcher = () =>
  axios.post('/api/v1/users/current').then((res) => res.data.data)

const logOut = (callback: () => void) => {
  axios.delete('/api/v1/sessions').then(() => {
    callback()
    Router.push('/auth/log-in')
  })
}
const useCurrentUser = () => {
  const { data, mutate, error, isLoading } = useSWR(
    '/api/v1/users/current',
    fetcher
  )

  return {
    user: data as UserResource,
    logOut: () => logOut(() => mutate(null)),
    mutate,
    isLoading,
    isError: error,
  }
}

export { useCurrentUser, useCurrentUser as useUser }

interface RedirectProps {
  goToLogin?: boolean
  redirectTo?: string
  redirectIfFound?: boolean
}

/* TODO: Can delete this once we've migrated auth across */
export const useAuthWrapper = ({
  redirectTo = '',
  redirectIfFound = false,
}: RedirectProps) => {
  const { user, isLoading } = useCurrentUser()

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || !user) return

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !user) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user)
    ) {
      Router.push(redirectTo)
    }
  }, [user, redirectIfFound, redirectTo])

  return { user, loading: isLoading }
}

interface AuthGateProps extends RedirectProps {
  children: React.ReactNode
}

/* TODO: Can delete this once we've switched auth across */
export const AuthUserWrapper = ({
  children,
  redirectIfFound,
  redirectTo,
}: AuthGateProps) => {
  const { loading } = useAuthWrapper({ redirectIfFound, redirectTo })

  if (loading) {
    return <SuspenseScreen />
  }

  return <>{children}</>
}
