import classnames from 'classnames'
import { InstagramIcon } from 'components/icons/social/Instagram'
import { MediumIcon } from 'components/icons/social/Medium'
import { TwitterIcon } from 'components/icons/social/Twitter'
import Link from 'next/link'
import { ReactNode } from 'react'

interface SocialMediaLinksProps {
  classNames?: string
  innerLinksClassNames?: string
}

interface InnerLinksProps {
  href: string
  text?: string
  glyph?: ReactNode
}

const SocialMediaLinks = ({
  classNames,
  innerLinksClassNames = 'hover:text-freeport-blue-base',
}: SocialMediaLinksProps) => {
  const SOCIAL_LINKS = [
    {
      text: 'Follow us on Medium',
      href: 'https://medium.com/@Freeport.app',
      glyph: <MediumIcon />,
    },
    {
      text: 'Follow us on Twitter',
      href: 'https://twitter.com/freeport_app/',
      glyph: <TwitterIcon />,
    },
    {
      text: 'Follow us on Instagram',
      href: 'https://www.instagram.com/freeport.app/',
      glyph: <InstagramIcon />,
    },
  ]

  const containerClassNames = classnames(
    'flex flex-column items-center gap-6',
    classNames
  )

  return (
    <ul className={containerClassNames}>
      {SOCIAL_LINKS.map(({ href, text, glyph }: InnerLinksProps) => (
        <li key={href}>
          <Link href={href} title={text} className={innerLinksClassNames}>
            {glyph}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export { SocialMediaLinks }
