import { FreeportLogo } from 'components/icons/freeport/logo'
import Image from 'next/image'

const ConfirmationModal = ({
  closeModal,
  accessCodeConfirmed,
}: {
  closeModal: () => void
  accessCodeConfirmed?: boolean
}) => {
  return (
    <article className="text-black bg-white rounded-lg w-full max-w-sm mx-auto text-center drop-shadow-2xl">
      <header className="relative w-full h-40">
        <Image
          src={
            accessCodeConfirmed
              ? '/images/modals/abstract-1.jpg'
              : '/images/modals/abstract-2.jpg'
          }
          alt=""
          fill
          className="rounded-t-lg object-cover"
        />
        <div className="h-fit w-fit absolute inset-1/2 -translate-x-1/2 -translate-y-1/2">
          <FreeportLogo
            className="h-12 w-12 text-white"
            typeClassName="h-32 w-32 text-white"
            variant="logo-type"
          />
        </div>
      </header>
      <section>
        <h2 className="text-freeport-display-sm font-bold px-4 mt-8">
          {accessCodeConfirmed ? 'Access code confirmed!' : 'Almost done!'}
        </h2>
        <p className="text-freeport-md font-regular mt-4 px-8 md:px-16">
          Check your email to confirm your subscription.
        </p>
      </section>
      <footer className="py-8">
        <button
          onClick={closeModal}
          className="mx-auto bg-freeport-primary-600 text-white text-sm leading-8 font-medium px-6 py-2 rounded"
        >
          Continue
        </button>
      </footer>
    </article>
  )
}

export { ConfirmationModal }
