import { FreeportLogo } from 'components/icons/freeport/logo'
import { KYCAMLStatus } from 'components/kyc/KYCAMLStatus'
import { KYCModal } from 'components/kyc/KYCModal'
import { ProfileIcon } from 'components/profile/ProfileIcon'
import { AnimatePresence } from 'framer-motion'
import { useThemeContext } from 'lib/context/themeContext'
import { useCurrentUser } from 'lib/hooks/useCurrentUser'
import { useInvestorDetails } from 'lib/hooks/useInvestorDetails'
import { useKYC } from 'lib/hooks/useKYC'
import { useShowKYCModal } from 'lib/state/useShowKYCModal'
import Link from 'next/link'
import { useLayoutEffect, useState } from 'react'
import { LAYOUT_CONTAINER_CLASS } from '../Layout'
import { HeaderLogo } from './HeaderLogo'
import { OverlayNav } from './OverlayNav'

const Header = ({ bannerProps }: any) => {
  const { user } = useCurrentUser()

  const [open, setOpen] = useState(false)

  const onToggleMenu = () => setOpen(!open)

  const { inviteDrawerOpen, handleInviteDrawerOpen } = useThemeContext()

  return (
    <>
      <header
        className="border-b border-b-freeport-gray-200 bg-white z-40 sticky top-0 w-full"
        id="hero-form"
      >
        <div className="mx-auto px-4 sm:px-8 lg:px-16 flex flex-col justify-end md:justify-center">
          <div className="flex justify-between relative z-20 py-2">
            <HeaderLogo />

            <div className="flex gap-6 lg:gap-8 items-center">
              <Link
                href="/discover"
                className="font-bold text-freeport-blue-dark border-b-2 border-transparent hover:border-freeport-blue-dark transition-colors duration-200 ease-in-out leading-6 mt-1"
              >
                Collections
              </Link>
              <button
                className="text-freeport-blue-dark w-10 h-6 relative focus:outline-none bg-white flex self-center"
                onClick={onToggleMenu}
                aria-label={open ? 'Close' : 'Open'}
              >
                <span className="sr-only">Open main navigation</span>

                <div className="block w-10 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <span
                    aria-hidden="true"
                    className={`${
                      open
                        ? '-rotate-45 w-8 rounded-sm h-1.5'
                        : '-translate-y-2 w-10 rounded-md h-[3px]'
                    } block absolute right-0 bg-current transform transition-all duration-500 ease-in-out`}
                  />

                  <span
                    aria-hidden="true"
                    className={`absolute flex justify-end w-full`}
                  >
                    <span
                      className={`${
                        open
                          ? 'rotate-45 w-8 rounded-sm h-1.5'
                          : 'translate-y-2 w-5 rounded-md left-0 right-0 h-[3px]'
                      } flex bg-current transform transition-all duration-500 ease-in-out`}
                    ></span>
                  </span>

                  <span
                    aria-hidden="true"
                    className={`${
                      open ? 'opacity-0' : ''
                    } block rounded-md absolute h-[3px] w-10 bg-current transform transition-all duration-300 ease-in-out`}
                  />
                </div>
              </button>
            </div>
          </div>
        </div>

        <AnimatePresence mode="wait">
          {open && (
            <OverlayNav
              key="overlay"
              isOpen={open}
              closeOverlay={onToggleMenu}
            />
          )}
        </AnimatePresence>
      </header>
    </>
  )
}

const AUTHENTICATED_LINKS = [
  {
    href: '/discover',
    text: 'Invest',
  },
  {
    href: '/auth/gallery',
    text: 'Your Gallery',
    target: '_blank',
  },
  {
    href: '/about',
    text: 'How It Works',
  },
  {
    href: '/faq',
    text: 'FAQ',
  },
]

const UserHeader = () => {
  const { requiresKYC, requiresAccreditedCheck } = useKYC()
  const { showKYCModal, setShowKYCModal } = useShowKYCModal()
  const { user, mutate, logOut } = useCurrentUser()
  const { investorDetails } = useInvestorDetails()
  const [open, setOpen] = useState(false)
  const onToggleMenu = () => setOpen(!open)

  useLayoutEffect(() => {
    if (requiresKYC || requiresAccreditedCheck) setShowKYCModal(true)
  }, [requiresKYC, requiresAccreditedCheck])

  return (
    <>
      <KYCModal
        open={showKYCModal}
        onClose={async () => {
          await mutate()
          setShowKYCModal(false)
        }}
      />
      <header className="border-b border-b-freeport-gray-200 bg-transparent lg:bg-white z-40 sticky top-0">
        <div
          className={`${LAYOUT_CONTAINER_CLASS} h-[81px] bg-white lg:bg-transparent lg:py-2 lg:flex lg:flex-row lg:justify-start lg:items-center lg:gap-x-10`}
        >
          <div
            className={`h-[81px] z-20 py-4 flex items-center justify-between text-freeport-gray-900`}
          >
            <Link href="/" className="z-20">
              <FreeportLogo className="h-8 w-8" variant="logo-type" />
            </Link>
            <div className="hidden sm:flex z-20 lg:hidden justify-center">
              <KYCAMLStatus handleStartKYC={() => setShowKYCModal(true)} />
            </div>
            <button
              className="lg:hidden z-20 text-freeport-blue-dark w-10 h-6 relative focus:outline-none bg-white flex self-center"
              onClick={onToggleMenu}
              aria-label={open ? 'Close' : 'Open'}
            >
              <span className="sr-only">Open main navigation</span>

              <div className="block w-10 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <span
                  aria-hidden="true"
                  className={`${
                    open
                      ? '-rotate-45 w-8 rounded-sm h-1.5'
                      : '-translate-y-2 w-10 rounded-md h-[3px]'
                  } block absolute right-0 bg-current transform transition-all duration-500 ease-in-out`}
                />

                <span
                  aria-hidden="true"
                  className={`absolute flex justify-end w-full`}
                >
                  <span
                    className={`${
                      open
                        ? 'rotate-45 w-8 rounded-sm h-1.5'
                        : 'translate-y-2 w-5 rounded-md left-0 right-0 h-[3px]'
                    } flex bg-current transform transition-all duration-500 ease-in-out`}
                  ></span>
                </span>

                <span
                  aria-hidden="true"
                  className={`${
                    open ? 'opacity-0' : ''
                  } block rounded-md absolute h-[3px] w-10 bg-current transform transition-all duration-300 ease-in-out`}
                />
              </div>
            </button>
          </div>
          <nav className="hidden lg:block h-[81px] bg-white lg:bg-transparent shadow-xl lg:shadow-none absolute left-0 w-full lg:w-auto lg:static lg:flex lg:flex-row lg:flex-grow lg:justify-between lg:items-center">
            <ul className="flex flex-col lg:flex-row py-4 lg:py-0 gap-2">
              {AUTHENTICATED_LINKS.map(({ href, text, target }) => (
                <li key={href}>
                  <Link
                    href={href}
                    target={target}
                    className="block px-4 lg:px-2 py-3 lg:py-1 text-freeport-gray-900 lg:hover:text-freeport-gray-600 hover:bg-freeport-gray-50 lg:hover:bg-inherit text-freeport-md font-semibold whitespace-nowrap"
                  >
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="gap-x-10 flex flex-row justify-between items-center">
              {(requiresKYC || requiresAccreditedCheck) && (
                <li className="pt-2 lg:p-0">
                  <KYCAMLStatus handleStartKYC={() => setShowKYCModal(true)} />
                </li>
              )}
              <li className="pt-2 lg:p-0">
                <ProfileIcon user={user} logOut={logOut} />
              </li>
            </ul>
          </nav>
        </div>
        {(requiresKYC || requiresAccreditedCheck) && (
          <div className="sticky z-20 top-20 py-2 px-4 flex-1 sm:hidden justify-center bg-white/90 w-full">
            <div className="flex justify-center">
              <KYCAMLStatus handleStartKYC={() => setShowKYCModal(true)} />
            </div>
          </div>
        )}
        <AnimatePresence mode="wait">
          {open && (
            <OverlayNav
              key="overlay"
              isOpen={open}
              closeOverlay={onToggleMenu}
            />
          )}
        </AnimatePresence>
      </header>
    </>
  )
}

export { Header, UserHeader }
