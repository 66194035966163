import useSWR from 'swr'

import axios from 'axios'

const fetcher = () =>
  axios.get('/api/v1/transactions').then((res) => res.data.data)

const useTransactions = () => {
  const { data, mutate, error, isLoading } = useSWR(
    '/api/v1/transactions',
    fetcher
  )

  return {
    transactions: data,
    mutate,
    isLoading,
    isError: error,
  }
}

export { useTransactions }
