const APP_NAME = 'freeport'
const BUSINESS_ADDRESS = '901 Broadway #24210, Nashville, TN 37202'
const OFFERING_CIRCULAR =
  'https://www.sec.gov/Archives/edgar/data/1946910/000182912623003143/freeportholdings_253g2.htm'
// TODO: We might want some way for users to copy these

// Wait until we've sold this amount to show progress
const MIN_PROGRESS_VISIBLE = 8000

const WIRE_DETAILS = [
  { label: 'Bank Name', value: 'Flagstar Bank' },
  {
    label: 'Address',
    value:
      'North Capital Private Securities Corp\n623 E Fort Union Blvd, Suite 101\nMidvale, UT 84047',
  },
  { label: 'Account Number', value: '1504614146' },
  { label: 'Routing Number (ABA)', value: '026013576' },
  { label: 'SWIFT ID', value: 'SIGNUS33' },
]

export {
  APP_NAME,
  BUSINESS_ADDRESS,
  MIN_PROGRESS_VISIBLE,
  OFFERING_CIRCULAR,
  WIRE_DETAILS,
}
