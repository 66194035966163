import { ThemeContextProvider } from 'lib/context/themeContext'
import { useCurrentUser } from 'lib/hooks/useCurrentUser'
import { ReactElement } from 'react'
import { FreeportLayoutProps } from 'src/pages/_app'
import { Footer } from './Footer'
import { Header, UserHeader } from './Header'

export const LAYOUT_CONTAINER_CLASS =
  'w-full px-4 md:mx-auto md:max-w-screen-xl md:px-8'

const AppLayout = ({ children }: FreeportLayoutProps) => {
  const { user } = useCurrentUser()
  return (
    <>
      {user ? <UserHeader /> : <Header />}
      <main className="flex flex-col flex-grow">{children}</main>
      <Footer />
    </>
  )
}

const withAppLayout = (page: ReactElement) => {
  return (
    <ThemeContextProvider>
      <AppLayout>{page}</AppLayout>
    </ThemeContextProvider>
  )
}

const AppContainer = ({
  children,
  className = '',
}: {
  children: ReactElement
  className?: string
}) => {
  return (
    <div className={className}>
      <div className={`${LAYOUT_CONTAINER_CLASS}`}>{children}</div>
    </div>
  )
}

export { AppContainer, AppLayout, withAppLayout }
