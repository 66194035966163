import { ParsedUrlQuery } from 'querystring'

const VALID_UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign']

// Fetch UTM params from Next Request
const getUTMContext = (query: ParsedUrlQuery) => {
  const utmContext: Record<string, any> = {}
  Object.keys(query).forEach((key) => {
    if (!key.startsWith('utm_')) return
    if (!VALID_UTM_PARAMS.includes(key)) return

    let contextKey = key.replace('utm_', '')
    if (contextKey === 'campaign') contextKey = 'name'

    utmContext[contextKey] = query[key]
  })
  return utmContext
}

export { getUTMContext }
