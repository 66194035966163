import {
  ACCREDITED_INVESTOR,
  NOT_ACCREDITED_INVESTOR,
} from 'lib/clients/north_capital'
import { UserResource } from 'src/resources/UserResource'
import { useUser } from './useCurrentUser'
import { useInvestorDetails } from './useInvestorDetails'
import { useTransactions } from './useTransactions'
type InvestorDetailsType = {
  accredited_investor?:
    | typeof ACCREDITED_INVESTOR
    | typeof NOT_ACCREDITED_INVESTOR
}

const requiresAccreditedCheck = (
  investor_details: InvestorDetailsType,
  transactions: any[]
) => {
  if (!investor_details) return false
  if (!transactions || !transactions.length || transactions.length === 0)
    return false
  if (
    investor_details.accredited_investor === undefined ||
    investor_details.accredited_investor === null
  )
    return true

  return false
}

const requiresKYC = (user: UserResource, transactions: any[]) => {
  if (!user) return false
  if (!user.north_capital_connected) return false
  if (!transactions || !transactions.length || transactions.length === 0)
    return false

  if (!user.kyc_aml_status) return true
  if (user.kyc_aml_status === 'NOT_STARTED') return true
  if (user.kyc_aml_status === 'INFO_REQUIRED') return true

  return false
}

const useKYC = () => {
  const { user, isLoading: loading_user } = useUser()
  const { investorDetails, isLoading: loading_investor_details } =
    useInvestorDetails()
  const { transactions, isLoading: loading_transactions } = useTransactions()

  return {
    requiresKYC: requiresKYC(user, transactions),
    requiresAccreditedCheck: requiresAccreditedCheck(
      investorDetails,
      transactions
    ),
    isLoading: loading_user || loading_transactions || loading_investor_details,
    status: user?.kyc_aml_status,
  }
}

export { requiresAccreditedCheck, requiresKYC, useKYC }
