import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

type ModalProps = {
  show: boolean
  children: React.ReactNode
  onClose?: () => void
}

const MODAL_PORTAL_ROOT_ID = 'modal-portal-root'

const Modal = ({ show, onClose, children }: ModalProps) => {
  const [portalEl, setPortalEl] = useState<HTMLElement | null>(null)
  const modalWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const el = document.getElementById(MODAL_PORTAL_ROOT_ID)
    setPortalEl(el)
  }, [])

  const onClickBackdrop = (e: any) => {
    if (e?.target === modalWrapperRef.current && onClose) onClose()
  }

  const content = show ? (
    <div
      onClick={onClickBackdrop}
      ref={modalWrapperRef}
      className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-40"
    >
      <div className="p-4">{children}</div>
    </div>
  ) : null

  if (portalEl) return ReactDOM.createPortal(content, portalEl)
  return <></>
}

export { Modal }
